import React from 'react'
// import { Link } from 'gatsby'

// import Layout from '../../fusion/layout'
// import Image from '../../components/image'
import SEO from '../../components/seo'

// import Pricing from '../../fusion/pricing'
// import Navbar from '../../fusion/navbar'
// iimport Hero from '../../fusion/hero'
// iimport Feature from '../../fusion/feature'
// iimport Service from '../../fusion/service'
// import VideoPromo from "../../fusion/video-promo"
// iimport Team from '../../fusion/team'
// import Counter from "../../fusion/counter"
// import Skill from "../../fusion/skill"
// import Portfolio from "../../fusion/portfolio"
// iimport Testimony from '../../fusion/testimonial'
// import Blog from "gatsby-theme-stack/src/blog"
// import Client from "gatsby-theme-stack/src/client"
// import Contact from '../../fusion/contact'
// import Action from '../../fusion/action'
// import About from '../../fusion/about'

import { translate } from 'react-i18next'

const IndexPage = () => (
  <div></div>
  /* <Layout>
    <SEO title="Home" />
    <Hero></Hero>
    <Feature></Feature>
    <Service></Service>
    <Team></Team>
    <Testimony></Testimony>
    <Contact></Contact>
  </Layout> */
)

export default IndexPage
